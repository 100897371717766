import * as Data from './culife/data';
import { ThemeType } from '@shared';

interface ThemeConfig {
  VectorLogo?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  HeaderLogo: string;
  DarkHeaderLogo: string;
  FooterLogo: string;
  DarkFooterLogo: string;

  headerLogoSize: number;
  footerLogoSize: number;

  defaultTheme: ThemeType;
  hasThemeSwitcher: boolean;
  hasNetworkSwitcher: boolean;
  hasWhatsappWidget: boolean;
  hasBanner: boolean;
  isDisableWalletLogin?: boolean;
}

// Theme config validation
Data satisfies ThemeConfig;

export const {
  DarkFooterLogo,
  DarkHeaderLogo,
  FooterLogo,
  HeaderLogo,
  VectorLogo,
  defaultTheme,
  footerLogoSize,
  hasBanner,
  hasNetworkSwitcher,
  hasThemeSwitcher,
  hasWhatsappWidget,
  headerLogoSize,
  isDisableWalletLogin,
} = Data;
